import styled from 'styled-components';

export const HeaderRegionName = styled.div`
margin-top: 1rem;
font-size: 38px;
color: #498205;
text-align: center;
margin-bottom: 1rem
`;

export const AssetName = styled.div`
.MuiTypography-body1 {
    font-family: "Segoe UI", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
}
`;

export const StyledLoader = styled.div`
`;

export const RadionButtons = styled.div`
`;

export const RadionAndImageWrapper = styled.div`
display: flex;
justify-content: space-between;
`;

export const DisclaimarText = styled.p`
margin: 5px;
font-size: 9pt;
color: #ea7c34;
`;
