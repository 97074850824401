import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel } from '@material-ui/core';
import React, { useState, useRef } from 'react';
import XLSX from 'sheetjs-style';
import _ from 'lodash';
import axios from 'axios';
import AlertDialog from 'components/DialogComponent/AlertDialog';
import { APIEndpoints } from 'helpers/APILists';
import { MESSAGE_TYPE } from 'journeys/common/common.constants';
import TextInput from '../../elements/TextInput';
import { useStyles } from './DpsUpload.styled';
import { trackErrors } from '../helper';
import DropdownInput from '../../elements/DropdownInput';
import { AdminConstants } from 'journeys/portal/Admin/AdminScreen.constants';
import { handleExportDps } from 'components/DataGrid/helpers/HandleExport';
import { formatDate } from 'helpers/DateFormatter';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Spinner, DarkBackground } from 'elements/Spinner/Spinner.styled';

const buttonBgColor = 'yellow';
const gridColumns = 'repeat(3, 1fr)';
const validateNameLength = (value) => {
    if (value.length < 5) {
        return ' *(At least 5 characters))';
    }
    return '';
};

const DpsUploadForm = ({ isOpen, title, onClose, rowData, onRecordSubmit }) => {
    const classes = useStyles();
    const inputRef = useRef(null);
    const { instance } = useMsal();
    const userName = instance.getActiveAccount();
    const [file, setFile] = useState(null);
    const [createRowData, setCreateRowData] = useState(rowData);
    const [sheetNames, setSheetNames] = useState([]);
    const [cancelAlert, setCancelAlert] = useState(false);
    const [buttonColor, setButtonColor] = useState(buttonBgColor);
    const [uploadError, setUploadError] = useState('');
    const [uploadMessage, setUploadMessage] = useState('');
    const [uniquePairs, setUniquePairs] = useState([]);
    const [beforeData, setBeforeData] = useState([]);
    const [afterData, setAfterData] = useState([]);
    const [userNameSizeError, setUserNameSizeError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const uploadButtonVisible = !_.isEqual(rowData, createRowData) && createRowData.SheetName && file && createRowData.UserNameForUpload && createRowData.UserNameForUpload.length >= 5;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCreateRowData((prev) => ({
            ...prev,
            [name]: value
        }));
        if (name === 'SheetName') {
            setUploadMessage('');
            setUploadError('');
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheet = workbook.Sheets[value];
                const jsonData = XLSX.utils.sheet_to_json(sheet);

                const uniquePairsList = _.uniqBy(jsonData, (pair) => `${pair.Region}-${pair.Asset}`)
                    .filter(pair => pair.Region !== undefined && pair.Asset !== undefined)
                    .map(pair => ({
                        Region: pair.Region,
                        Asset: pair.Asset
                    }));
                setUniquePairs(uniquePairsList);
                const recordCount = jsonData.length;
                setCreateRowData((prev) => ({
                    ...prev,
                    RecordCount: recordCount
                }));
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const dataFormatter = (tableData) => tableData.map(({ RowId, ...item }) => ({
        ...item,
        CreatedDate: item.CreatedDate && formatDate(new Date(item.CreatedDate)),
        LastModifiedDate: item.LastModifiedDate && formatDate(new Date(item.LastModifiedDate)),
    }));

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'UserNameForUpload') {
            setUserNameSizeError(validateNameLength(value));
        }
    };

    const cancelButtonHandler = () => {
        if (_.isEqual(rowData, createRowData)) {
            onClose();
        } else {
            setCancelAlert(true);
        }
    };

    const handleUnsaveChanges = () => {
        setCancelAlert(false);
    };

    const resetButtonHandler = () => {
        setCreateRowData(rowData);
        setFile(null);
        setSheetNames([]);
        setButtonColor(buttonBgColor);
        setUploadError('');
        setUploadMessage('');
        setUserNameSizeError('');
        if (inputRef.current) {
            inputRef.current.value = null;
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            return;
        }
        setButtonColor('rgb(255, 229, 175)');
        setSheetNames([]);
        setFile(selectedFile);
        setCreateRowData((prev) => ({
            ...prev,
            SheetName: '',
            UserNameForUpload: '',
            Comments: '',
            FileName: selectedFile.name
        }));
        setUploadMessage('');
        setUploadError('');

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNamesFromFile = workbook.SheetNames;
            setSheetNames(sheetNamesFromFile);
        };
        reader.readAsArrayBuffer(selectedFile);
    };

    const submitRecord = (dataRow) => {
        axios.post(APIEndpoints.ADMIN_AddDPSUploadlog, dataRow)
            .then((res) => {
                onRecordSubmit(res.data.RowId);
            })
            .catch((err) => {
                trackErrors({
                    messageType: MESSAGE_TYPE.ERROR,
                    message: err.message,
                    exception: err
                });
            });
    };

    const uploadButtonHandler = async () => {
        setIsLoading(true);
        try {
            let beforeFlattenedArray = [];
            if (uniquePairs.length > 0) {
                const responses = await Promise.all(
                    uniquePairs.map(pair => axios.post(APIEndpoints.DPS_GetAssetDPSList, pair))
                );

                const beforeDataList = responses.map(response => response?.data);
                beforeFlattenedArray = [].concat(...beforeDataList);
                setBeforeData(beforeFlattenedArray);
            }

            const formData = new FormData();
            formData.append('File', file);
            formData.append('SheetName', createRowData.SheetName);
            formData.append('CreatedBy', createRowData.UserNameForUpload);
            formData.append('ModifiedBy', createRowData.UserNameForUpload);

            const res = await axios.post(APIEndpoints.DPSUpload, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const {
                success: uploadStatus,
                message: success,
                insertedRecordsCount,
                updatedRecordsCount,
                duplicates: duplicateRecords
            } = res?.data || {};

            setUploadMessage({
                uploadStatus,
                success,
                insertedRecordsCount,
                updatedRecordsCount,
                duplicateRecords
            });
            createRowData.RowId = 0;
            createRowData.UploadStatus = uploadStatus ? 'Success' : '';
            createRowData.UploadedBy = userName?.name;
            createRowData.UploadedDate = formatDate(new Date());
            createRowData.InsertedRecordCount = insertedRecordsCount;
            createRowData.UpdatedRecordCount = updatedRecordsCount;
            createRowData.DuplicateRecordCount = duplicateRecords?.length || 0;
            submitRecord(createRowData);

            if (uniquePairs.length > 0) {
                const afterResponses = await Promise.all(
                    uniquePairs.map(pair => axios.post(APIEndpoints.DPS_GetAssetDPSList, pair))
                );
                const afterDataList = afterResponses.map(response => response?.data);
                const afterFlattenedArray = [].concat(...afterDataList);
                const beforeDataMap = new Map(beforeFlattenedArray.map(item => [item?.RowId, item]));
                const filteredNewData = afterFlattenedArray.filter(afterItem => {
                    const beforeItem = beforeDataMap.get(afterItem?.RowId);
                    return !beforeItem || afterItem?.LastModifiedDate !== beforeItem?.LastModifiedDate;
                });
                setAfterData(filteredNewData);
            }
        } catch (err) {
            trackErrors({
                messageType: MESSAGE_TYPE.ERROR,
                message: err.message,
                exception: err
            });

            const {
                success: uploadStatus,
                errors: apiMessageFailure,
                invalidCellls
            } = err?.response?.data || {};

            setUploadError({
                failure: 'Error submitting DPS data.',
                uploadStatus,
                apiMessageFailure,
                invalidCellls
            });
            setUploadMessage(err?.response?.data?.message);
            if (!(err?.response?.data?.message)) {
                setUploadMessage('An error occurred during upload. Please reselect the file.');
                setCreateRowData(rowData);
                setFile(null);
                setSheetNames([]);
                setButtonColor(buttonBgColor);
                setUserNameSizeError('');
                if (inputRef.current) {
                    inputRef.current.value = null;
                }
            }
            createRowData.RowId = 0;
            createRowData.UploadStatus = uploadError?.uploadStatus ? '' : 'Failure';
            createRowData.UploadedBy = userName?.name;
            createRowData.UploadedDate = formatDate(new Date());
            createRowData.InsertedRecordCount = 0;
            createRowData.UpdatedRecordCount = 0;
            createRowData.DuplicateRecordCount = 0;
            submitRecord(createRowData);
        } finally {
            setIsLoading(false);
        }
    };

    const downloadSnapshotHandler = async () => {
        try {
            const formattedBeforeData = dataFormatter(beforeData);
            const formattedAfterData = dataFormatter(afterData);
            handleExportDps(formattedBeforeData, formattedAfterData);
        } catch (err) {
            trackErrors({
                messageType: MESSAGE_TYPE.ERROR,
                message: err.message,
                exception: err
            });
        }
    };

    return (
        <Dialog
            open={isOpen}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title" className={classes.header}>
                {title}
            </DialogTitle>
            {cancelAlert && (
                <AlertDialog
                    dialogMessage={AdminConstants.DIALOG_MESSAGE}
                    dialogOpenState={cancelAlert}
                    handleClose={handleUnsaveChanges}
                    handleAlertFunction={onClose}
                />
            )}
            <DialogContent>
                <div style={{ display: 'grid', gridTemplateColumns: gridColumns, gap: '0 10px' }}>
                    <div>
                        <InputLabel htmlFor="file-upload" style={{ fontSize: '12px', color: 'black', paddingLeft: '3px', paddingBottom: '2px', fontWeight: '600', marginBottom: '0' }}>
                            Select An Excel File
                        </InputLabel>
                        <label htmlFor="file-upload" style={{ display: 'block', marginBottom: '0px' }}>
                            <input
                                type="file"
                                accept=".xlsx, .xls"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-upload"
                                ref={inputRef}
                            />
                            <Button
                                variant="outlined"
                                size="small"
                                component="span"
                                style={{ textTransform: 'capitalize', fontSize: '11px', color: 'black', height: '27px', width: '250px', backgroundColor: buttonColor, marginTop: '2px', marginBottom: '0' }}
                            >
                                Select File
                            </Button>
                        </label>
                    </div>
                    <DropdownInput
                        name="SheetName"
                        title="Select Sheet Name"
                        value={createRowData.SheetName}
                        handleInputChange={handleInputChange}
                        options={sheetNames.map(name => ({ label: name, value: name }))}
                        required
                    />
                    <TextInput
                        name="UserNameForUpload"
                        title="User Name For Upload "
                        value={createRowData.UserNameForUpload}
                        handleInputChange={handleInputChange}
                        handleBlur={handleBlur}
                        errorMsg={userNameSizeError}
                        maxLength="30"
                        required
                    />
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: gridColumns, gap: '0 10px' }}>
                    {createRowData.FileName ? (
                        <div style={{ color: 'black', fontSize: '11px', paddingLeft: '3px' }}>
                            {createRowData.FileName}
                        </div>
                    ) : <div />}
                    <TextInput
                        name="UploadedBy"
                        title="Uploaded By"
                        value={userName?.name}
                        disabled
                    />
                    <TextInput
                        name="UploadedDate"
                        title="Uploaded Date"
                        value={formatDate(new Date())}
                        disabled
                    />
                </div>
                <div style={{ display: 'grid', marginTop: '12px' }}>
                    <TextInput
                        name="Comments"
                        title="Comments"
                        value={createRowData.Comments}
                        handleInputChange={handleInputChange}
                        isMultiline
                        width="100%"
                    />
                </div>
                {isLoading ? (
                    <DarkBackground disappear={!isLoading}>
                        <Spinner />
                    </DarkBackground>
                ) : (
                    <div style={{ display: 'grid', gridTemplateColumns: gridColumns, gap: '0 10px' }}>
                        {uploadMessage?.uploadStatus && uploadMessage && (
                            <div style={{ marginTop: '15px', fontSize: '12px', gridColumn: 'span 3' }}>
                                <div style={{ color: 'green', marginBottom: '10px' }}>{uploadMessage.success}</div>
                                <div>
                                    InsertedRecordsCount:
                                    {uploadMessage.insertedRecordsCount}
                                </div>
                                <div>
                                    UpdatedRecordsCount:
                                    {uploadMessage.updatedRecordsCount}
                                </div>
                                {Array.isArray(uploadMessage?.duplicateRecords) && (uploadMessage.duplicateRecords).length > 0 ? (
                                    <div>
                                        Duplicate Records:
                                        {uploadMessage.duplicateRecords.join(', ')}
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {!uploadError?.uploadStatus && uploadMessage && uploadError && (
                            <div style={{ marginTop: '15px', fontSize: '12px', gridColumn: 'span 3' }}>
                                <div style={{ color: 'red', marginBottom: '10px' }}>{uploadError?.failure}</div>
                                <div>{uploadMessage}</div>
                                {Array.isArray(uploadError?.apiMessageFailure) ? (
                                    uploadError?.apiMessageFailure.map((errMsg) => (
                                        <div key={uuidv4()}>{errMsg}</div>
                                    ))
                                ) : (
                                    <div>{uploadError.apiMessageFailure}</div>
                                )}
                                {Array.isArray(uploadError?.invalidCellls) ? (
                                    <div>
                                        Invalid Cells:
                                        {uploadError.invalidCellls.join(', ')}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </DialogContent>

            <DialogActions>
                <div style={{ display: 'flex', gap: '0 8px' }}>
                    {uploadMessage?.uploadStatus && !isLoading
                        && (
                            <Button
                                classes={{ root: classes.button }}
                                variant="outlined"
                                onClick={downloadSnapshotHandler}
                            >
                                Download Snapshot
                            </Button>
                        )}
                    {uploadButtonVisible
                        && (
                            <Button
                                classes={{ root: classes.button }}
                                variant="outlined"
                                onClick={uploadButtonHandler}
                            >
                                Upload
                            </Button>
                        )}
                    <Button
                        classes={{ root: classes.button }}
                        onClick={resetButtonHandler}
                        variant="outlined"
                    >
                        Reset
                    </Button>
                    <Button
                        classes={{ root: classes.button }}
                        onClick={cancelButtonHandler}
                        variant="outlined"
                    >
                        Close
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
export default DpsUploadForm;

DpsUploadForm.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onRecordSubmit: PropTypes.func,
    rowData: PropTypes.object,
    title: PropTypes.string
};
